<template>
  <div class="gordo" v-if="datos">
    <div class="titulo">
        <label>{{datos.ID}}</label>
    </div>
    <button @click="eliminar">Eliminar</button>
    <PresuGeneral :datos="datos"/>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import PresuGeneral from '@/components/PresuGeneral.vue'
import PresuFacturas from '@/components/PresuFacturas.vue'
export default {
    data: function () {
        return {
            activo: 'general',
            tabla: 'presupuestos',
        }
    },
    components: {
        PresuGeneral,
        PresuFacturas
    },
    props:{
        id: {
            type: String,
            required: true
        },
        datos: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState(['detalles','listas','cache','tablas']),
    },
    methods: {

    },
    beforeMount(){
        this.tablas[this.tabla].campos.forEach(campo => {
            if(campo.tipo==='boolean'){
                this.datos[campo.nombre] = Boolean(parseInt(this.datos[campo.nombre]))
            }
        })
    }
}
</script>

<style scoped>
.gordo{
    width: 800px;
}
.titulo{
    font-size: 180%;
    margin: 1%;
    color: black;
}
.secciones label{
    border: 1px solid rgb(245, 138, 67);
    border-radius: 5px;
    padding: 6px;
    margin: 3px;
    cursor: pointer;
}
.secciones label:hover{
    background-color: rgb(245, 138, 67);
    color: white;
}
.general{
    margin: 1.5%;
    text-align: left;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1.5%;
}
.form > div label{
    display: block;
}
.form > div {
    margin: 1.5%;
}
.v-select{
    width: max-content;
    min-width: 100px;
}
</style>