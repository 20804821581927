<template>
  <div class="container">
      <Subtabla tabla="facturas_geo" tabla_preferencias="facturas_geo" :filtros="[{'campo':'OBRA','valor':obra}]" />
  </div>
</template>

<script>
import Subtabla from '@/components/Subtabla.vue'
import {mapState} from 'vuex'
export default {
    name: 'PresuFacturas',
    props:{
        obra: String
    },
    components: {
        Subtabla
    },
    computed:{
        ...mapState(['tablas','preferencias','cache','listas','detalles'])
    }
}
</script>

<style scoped>
.container{
    overflow: scroll;
}
</style>