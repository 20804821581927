<template>
    <form class="general" v-if="datos">
        <div class="form primera">
            <div>
                <input name="NUM" type="number" v-model="datos.NUM">
                <input name="LETRA" type="text" v-model="datos.LETRA">
            </div>
            <div>
                <label for="">Dirección</label>
                <input name="DIRECCION" type="text" v-model="datos.DIRECCION">
            </div>
            <div>
                <label for="">Población</label>
                <input name="POBLACION" type="text" v-model="datos.POBLACION">
            </div>
            <div>
                <label for="">Contacto</label>
                <input name="CONTACTO" type="text" v-model="datos.CONTACTO">
            </div>
            <div>
                <label for="">Cliente</label>
                <div>
                    <input type="text" @input="filtrar(listas.empresas,'cliente',busquedacliente)" v-model="busquedacliente">
                    <Desplegable :multiple="false" :key="key" v-if="listas.cliente" v-model="datos.CLIENTE" :datos="listas.cliente" @input="changes(null,['CLIENTE',datos.CLIENTE])"/>
                    <!-- <v-select @input="changes(null,['CLIENTE',datos.CLIENTE])" v-if="listas.cliente" :reduce="option => option.code" :options="listas.cliente" v-model="datos.CLIENTE"></v-select> -->
                </div>
            </div>
            <div>
                <label for="">Contacto Cliente</label>
                <!-- <v-select @input="changes(null,['CON_CLIENTE',datos.CON_CLIENTE])" name="CON_CLIENTE" v-if="listas.con_cliente" :reduce="option => option.code" :options="listas.con_cliente" v-model="datos.CON_CLIENTE"></v-select> -->
            </div>
            <div>
                <label for="">Arquitecto</label>
                <div>
                    <input type="text" @input="filtrar(listas.empresas,'arquitecto',busquedaarquitecto)" v-model="busquedaarquitecto">
                    <!-- <v-select @input="changes(null,['ARQUITECTO',datos.ARQUITECTO])" name="ARQUITECTO" v-if="listas.cliente" :reduce="option => option.code" :options="listas.arquitecto" v-model="datos.ARQUITECTO"></v-select> -->
                </div>
            </div>
            <div>
                <label for="">Contacto Arquitecto</label>
                <!-- <v-select @input="changes(null,['CON_ARQUITECTO',datos.CON_ARQUITECTO])" name="CON_ARQUITECTO" v-if="listas.con_arquitecto" :reduce="option => option.code" :options="listas.con_arquitecto" v-model="datos.CON_ARQUITECTO"></v-select> -->
            </div>
            <div>
                <label for="">Principal</label>
                <Desplegable :multiple="false" :datos="listas.principal"/>
            </div>
        </div>
        <div class="form segunda">
            <div>
                <label for="">Descripción</label>
                <input name="DESCRIPCION" type="text" v-model="datos.DESCRIPCION">
            </div>
            <div>
                <label for="">Plan Trabajo</label>
                <input name="PLAN_TRABAJO" type="text" v-model="datos.PLAN_TRABAJO">
            </div>
            <div>
                <label for="">Tipo Construcción</label>
                <Desplegable :multiple="false" :datos="listas.tipo_construccion"/>
            </div>
            <div>
                <label for="">Tipo Terreno</label>
                <Desplegable :multiple="false" :datos="listas.tipo_terreno"/>
            </div>
            <div>
                <label for="">Tipo Obra</label>
                <Desplegable :multiple="false" :datos="listas.tipo_obra"/>
            </div>
            <div>
                <label for="">Tipo Proyecto</label>
                <Desplegable :multiple="false" :datos="listas.tipo_proyecto"/>
            </div>
            <div>
                <label for="">Geólogo</label>
                <Desplegable :multiple="false" :datos="listas.geologo"/>
            </div>
            <div>
                <label for="">Idioma</label>
                <Desplegable :multiple="false" :datos="listas.idioma"/>
            </div>
        </div>
        <div class="form tercera">
            <div>
                <label for="">Visado</label>
                <input type="checkbox" name="VISADO" v-model="datos.VISADO">
            </div>
            <div>
                <label for="">Importe</label>
                <input type="number" name="IMPORTE" v-model="datos.IMPORTE">
            </div>
            <div>
                <label for="">Tipo Cliente</label>
                <Desplegable :multiple="false" :datos="listas.pub_priv"/>
            </div>
            <div>
                <label for="">Fecha Solicitud</label>
                <input type="date" name="FECHA_SOLICITUD" v-model="datos.FECHA_SOLICITUD">
            </div>
            <div>
                <label for="">Fecha Envío</label>
                <input type="date" name="FECHA_ENVIO" v-model="datos.FECHA_ENVIO">
            </div>
            <div>
                <label for="">Email Envío</label>
                <input type="text" name="EMAIL_ENVIO" v-model="datos.EMAIL_ENVIO">
            </div>
            <div>
                <label for="">Fecha Aceptación</label>
                <input type="date" name="FECHA_ACEPTACION" v-model="datos.FECHA_ACEPTACION">
            </div>
            <div>
                <label for="">Fecha Informe</label>
                <input type="date" name="FECHA_INFORME" v-model="datos.FECHA_INFORME">
            </div>
            <div>
                <label for="">Observaciones</label>
                <textarea name="OBSERVACIONES" cols="50" rows="7" v-model="datos.OBSERVACIONES"></textarea>
            </div>
        </div>
    </form>
</template>

<script>
import {mapState,mapActions,mapMutations} from 'vuex'
import Desplegable from '@/components/Desplegable.vue'
export default {
    name: 'PresuGeneral',
    data: function (){
        return {
            busquedaarquitecto: null,
            busquedacliente: null,
            tabla: 'presupuestos',
            id: this.$route.params.id,
            key:1
        }
    },
    props:{
        datos: Object
    },
    computed: {
        ...mapState(['tablas','detalles','listas','cache']),
    },
    components:{
        Desplegable
    },
    methods: {
    
    }
}
</script>

<style scoped>
.titulo{
    font-size: 180%;
}
.general{
    color: black;
    margin: 1.5%;
    text-align: left;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1.5%;
}
.form > div label{
    display: block;
}
.form > div {
    margin: 1.5%;
}
.v-select{
    width: max-content;
    min-width: 100px;
}

</style>